import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import BaseTopNav from '@/components/base/baseTopNav';
import {
  Accordion,
  AccordionItem,
  Button,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useEffect, useMemo, useState } from 'react';

/**
 *
 * @returns
 *  -------这个页面 是工具页面，仅开发人员使用-------
 */

export default function () {

  const { socketNewData } = useModel('socket');
  const [list, setList] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedType, setSelectedType] = useState('All');

  useEffect(() => {
    if (socketNewData) {
      setList((oldArr: any) => {
        return [socketNewData, ...oldArr].slice(0, 500);
      });
    }
  }, [socketNewData]);

  const types = useMemo(() => {
    const typeSet = new Set();
    list.forEach((item) => {
      if (item && item.type) {
        typeSet.add(item.type);
      }
    });
    return ['All', ...Array.from(typeSet)];
  }, [list]);

  const filteredList = useMemo(() => {
    return list.filter((item) => {
      if (!item) return false; // 跳过 undefined 项

      // 根据 selectedType 进行筛选
      if (
        selectedType &&
        selectedType !== 'All' &&
        item.type !== selectedType
      ) {
        return false;
      }
      // 根据搜索输入进行筛选
      if (inputValue) {
        // 搜索 item.data，忽略大小写
        const dataStr = JSON.stringify(item.data ?? '').toLowerCase();
        return dataStr.includes(inputValue.toLowerCase());
      }
      return true;
    });
  }, [list, selectedType, inputValue]);

  function HighlightedText({ text, highlight }) {
    // NO-TRANSLATION
    if (!text) {
      return null;
    }
    if (!highlight || !highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, i) =>
          regex.test(part) ? (
            <span key={i} className=" bg-errorColor">
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
      </>
    );
  }

  return (
    <>
      <BaseTopNav title="socketDebug" />
      <div className="pt-3 text-[12px]  bg-backgroundAuxiliaryColor ">
        <div className="p-3 w-full">
          <BaseInput
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="请输入搜索关键字"
            startContent={
              <BasilSearchSolid className="text-iconFontColor text-xl" />
            }
          />
          <div className="bg-backgroundAuxiliaryColor border-1 border-successColor mt-3">
            <Select
              className="w-full mt-2"
              placeholder="请选择类型"
              selectedKeys={[selectedType]}
              classNames={{
                trigger: `bg-backgroundAuxiliaryColor rounded-md h-[50px]`,
              }}
              popoverProps={{
                classNames: {
                  content: 'bg-backgroundAuxiliaryColor text-foreground',
                },
              }}
            >
              {types.map((type) => (
                <SelectItem
                  key={type}
                  value={type}
                  onClick={() => {
                    setSelectedType(type);
                  }}
                >
                  {type}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>

        <div className="h-[50vh] overflow-y-auto">
          <Accordion>
            {filteredList.map((item: any, index: number) => (
              <AccordionItem
                key={item?.time || index}
                title={item?.type || 'Unknown Type'}
              >
                <HighlightedText
                  text={JSON.stringify(item?.data ?? '')}
                  highlight={inputValue}
                />
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>

      <Button
        onClick={() => {
          setList([]);
        }}
      >
        <div className="text-xs flex justify-between items-center">
          <img
            src={require('@/assets/img/trade/short.png')}
            className="w-[15px] h-[15px] mr-1"
          />
          <div>清空</div>
        </div>
      </Button>
    </>
  );
}
